import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubMenu from "../components/sub-menu"
import ScrollHelper from "../components/scrollHelper"
import BrandLogo from "../components/brand-logo"
import MartHeaderVideo from "../components/mart-header"
import ZiqqiMartMainBrand from "../images/ziqqi-mart-brand.svg"
import ZiqqiMartSecondaryPurple from "../images/ziqqi-mart-secondary-purple.svg"
import ZiqqiMartSecondaryWhite from "../images/ziqqi-mart-secondary-white.svg"
import MartVideo from "../components/mart-video"
import BrandIntro from "../components/brand-intro"
import Fullscreen from "../components/fullscreen"
import Button from "../components/button"
import ColorPalette from "../components/color-palette"
import SubTitle from "../components/sub-title"
import PreFooter from "../components/pre-footer"
import MartVideoHeader from "../video/ziqqi-mart-logo.mp4";
import MartVideoHeaderMobile from "../video/ziqqi-mart-mobile.mp4";
import Footer from "../components/footer"

const BrandLogoContainer = () => {
  return(
    <React.Fragment>
      <section data-target data-value="Logo">
        <BrandLogo title="Main Brand — Positive" pathSvg={<ZiqqiMartMainBrand/>} background="#ffffff" color="#000000" classButton="purple-version" mainClass="big-brand"/>
        <article className="brand-logo">
          <div className="row expanded align-center align-middle">
            <div className="xxlarge-16 columns">
              <div className="row expanded xxlarge-up-2 xsmall-up-1">
                <div className="columns nopadd">
                  <BrandLogo title="Secondary variation — Positive" pathSvg={<ZiqqiMartSecondaryPurple/>} background="#f6f6f6" color="#000000" classButton="purple-version" mainClass="small-brand"/>
                </div>
                <div className="columns nopadd">
                  <BrandLogo title="Secondary variation — Negative" pathSvg={<ZiqqiMartSecondaryWhite/>} background="#656DFF" color="#ffffff" classButton="purple-white-version" mainClass="small-brand"/>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </React.Fragment>
  )
}

const subMenuList = ["Introduction", "Video", "Illustration", "Logo", "Colors"];

const subBrands = [
  {
    url: '/',
    imageURL: '../full-screen-brand-image.jpg',
    title: 'ZIQQI',
    text: 'Brings Africa the reliable one-stop online shopping experience, offering the widest variety of products.'
  },
  {
    url: '/ziqqi-fresh/',
    imageURL: '../ziqqi-fresh-fullscreen-01.jpg',
    title: 'ZIQQI Fresh',
    text: 'Supplies locally sourced, all organic fruits and vegetables, meats, fish and even dairy products.'
  }
]

const palettesGroup = [
  {
    palette: [
      {
        class: 'main',
        color: '',
        background: '#666EFF',
        colorElements: '#ffffff',
        properties: [
          {
            rgb: '102 / 110 / 255'
          },
          {
            cmyk: '69 / 62 / 0 /0',
          }
        ]
      },
      {
        color: '',
        background: '#22DF98',
        colorElements: '#000000',
        properties: [
          {
            rgb: '35 / 235 / 165',
          },
          {
            cmyk: '60 / 0 / 54 / 0'
          }
        ]
      }
    ]
  },
  {
    palette: [
      {
        class: 'secondary',
        color: '',
        background: '#F3777A',
        colorElements: '#ffffff',
        properties: [
          {
            rgb: '255 / 122 / 122',
          },
          {
            cmyk: '0 / 66 / 42 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#FECC92',
        colorElements: '#000000',
        properties: [
          {
            rgb: '255 / 204 / 147',
          },
          {
            cmyk: '0 / 22 / 45 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#93D0B1',
        colorElements: '#000000',
        properties: [
          {
            rgb: '131 / 235 / 188',
          },
          {
            cmyk: '43 / 0 / 38 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#FACBCC',
        colorElements: '#000000',
        properties: [
          {
            rgb: '255 / 204 / 204',
          },
          {
            cmyk: '0 / 24 / 11 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#FFE7CC',
        colorElements: '#000000',
        properties: [
          {
            rgb: '255 / 230 / 204',
          },
          {
            cmyk: '0 / 10 / 19 / 0'
          }
        ]
      },
      {
        color: '',
        background: '#DCF0E8',
        colorElements: '#000000',
        properties: [
          {
            rgb: '219 / 249 / 236',
          },
          {
            cmyk: '12 / 0 / 10 / 0'
          }
        ]
      }
    ]
  }
]

const MartHeader = () => {
  return(
    <section className="mart-header">
      <ScrollHelper />
      <MartHeaderVideo url={MartVideoHeader} urlMobile={MartVideoHeaderMobile}/>
      <Button name="Download the kit" caption="Includes all the variations of the logo." href="/" class="purple-white-version ziqqi-button"></Button>
    </section>
  )
}

const ZiqqiMart = () => (
  <Layout>
    <SEO title="ZIQQI Mart" keywords={[`ziqqi mart`, `application`, `react`]} />
    <SubMenu list={subMenuList} backgroundColor="purple-background"/>
    <MartHeader />
    <BrandIntro class="mart-intro intro" subtitle="Creating a sub-brand that changes local shops into online supermarkets and turns any individual into a ZIQQI affiliate sales agent." text="Any shop can offer ZIQQI's wide variety of products to customers and earn a commission on every transaction. ZIQQI Mart is the ticket to go beyond beyond big cities and into rural markets." value="Introduction"/>
    <MartVideo value="Video"/>
    <section className="illustration-universe" data-target data-value="Illustration">
      <SubTitle content="Illustration Universe"/>
      <Fullscreen url="../illustration-universe" />
    </section>
    <BrandLogoContainer />
    <ColorPalette palettes={palettesGroup} value="Colors"/>
    <Fullscreen url="../ziqqi-mart-fullscreen-02" />
    <PreFooter subBrands={subBrands}/>
    <Footer />
  </Layout>
)

export default ZiqqiMart

