import React from 'react'
import SubTitle from "../components/sub-title"
import Video from "../components/video"
import VideoMart from "../video/video_mart.mp4";

export default class MartVideo extends React.Component{
 
    constructor(props){
        super()
    }
    
    
    render(){

        return(
            <section className="mart-video page-videos-container" data-target data-value={this.props.value}>
                <div className="row expanded align-center">
                    <div className="xxlarge-12">
                        <div className="row expanded">
                            <div className="xxlarge-10 xsmall-14">
                                <SubTitle content="How ZIQQI Mart works explained by video." />
                            </div>
                            <div className="xxlarge-16">
                                <div className="mart-video-container big-video-container">
                                    <Video
                                        videoSrcURL={VideoMart} posterVideo="../posterVideoMart.png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
        )
    }
}
