import React from 'react'

export default class MartHeader extends React.Component{
 
    constructor(props){
        super()
    }

    componentDidMount(){
        var playPromiseMart = document.querySelector(".mart-header video").play();
        if (playPromiseMart !== undefined) {
          playPromiseMart.then(function() {        
          }).catch(function(error) {
          });
        }
    }
    
    
    render(){
        if(typeof window !== 'undefined'){
            if(window.innerWidth > 768){
                return(
                    <section className="mart-header-video">
                        <div className="block-bg-cover"> 
                            <video className="element-cover" autoPlay muted playsInline>
                                <source src={this.props.url} type="video/mp4"/>
                            </video>
                        </div>
                    </section>
                )
            } else {
                return(
                    <section className="mart-header-video">
                        <div className="block-bg-cover"> 
                            <video className="element-cover" autoPlay muted playsInline>
                                <source src={this.props.urlMobile} type="video/mp4"/>
                            </video>
                        </div>
                    </section>
                )
            }
        } else {
            return null;
        }
    }
}